import React, { PureComponent } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';
import { notification } from 'antd';
import EncodeUrl from '@/utils/encode';
import cookie from 'js-cookie';
// import dynamic from 'next/dynamic';
import publicRuntimeConfig from '@/utils/config';

@connect(() => ({}))
class Footer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      backToTop: false,
      email: '',
      checkLanguage: true,
      dataProvinces: [],
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch({
      type: 'webs/fetchAutoComplete',
      payload: {
        filter: {
          typesId: '2',
          isInternational: '0',
        },
        // sort: ['countrieName', 'asc'],
        range: [0, 6],
        // attributes: `id,countrieName`,
      },
      // payload: {
      //   filter: JSON.stringify({ isInternational }),
      //   sort: JSON.stringify(['provinceName', 'asc']),
      //   range: JSON.stringify([0, 20]),
      //   attributes: `id,provinceName,provinceIdentificationCode`,

      // },
      callback: res => {
        if (res.success) {
          // eslint-disable-next-line no-underscore-dangle
          const dataProvinces = (res.result && res.result.list) || [];
          this.setState({
            dataProvinces,
          });
        }
      },
    });

    document.addEventListener('scroll', this.checkScroll, { passive: true });
    document.body.addEventListener('scroll', this.checkScroll, { passive: true });

    const language = cookie.get('languagesId');
    this.setState({
      checkLanguage: language ? language === '2' : true,
    });
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.checkScroll);
    document.body.removeEventListener('scroll', this.checkScroll);
  }

  checkScroll = e => {
    const scrollLimit = 150;
    const { backToTop } = this.state;
    if (e.srcElement && e.srcElement.scrollingElement) {
      if (e.srcElement.scrollingElement.scrollTop > scrollLimit && !backToTop) {
        this.setState({
          backToTop: true,
        });
      } else if (e.srcElement.scrollingElement.scrollTop < scrollLimit && backToTop) {
        this.setState({
          backToTop: false,
        });
      }
    } else if (e.srcElement) {
      if (e.srcElement.scrollTop > scrollLimit && !backToTop) {
        this.setState({
          backToTop: true,
        });
      } else if (e.srcElement.scrollTop < scrollLimit && backToTop) {
        this.setState({
          backToTop: false,
        });
      }
    }
  };

  backToTop = e => {
    e.preventDefault();
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      700
    );
    this.setState({
      backToTop: false,
    });
  };

  handleProvince = item => {
    window.location.href = `/tim-kiem-dia-diem/${EncodeUrl(item.provinceName)}/${item.id}`;
  };

  render() {
    const { backToTop, dataProvinces } = this.state;
    const { dataSite, menuFooter, dataAd } = this.props;
    // console.log('this.poprs: ', this.props);
    const address =
      (dataSite.siteProfiles && dataSite.siteProfiles.address) ||
      (dataSite.places && dataSite.places.address);
    const mobile =
      (dataSite.siteProfiles && dataSite.siteProfiles.hotline) ||
      (dataSite.places && dataSite.places.mobile);
    const email =
      (dataSite.siteProfiles && dataSite.siteProfiles.email) ||
      (dataSite.places && dataSite.places.email);
    let slides = [];
    if (dataAd && dataAd.list) {
      slides = dataAd.list.filter(a => a.adsPositionsId === '34');
    }

    // console.log('menuFooter: ', menuFooter);

    return (
      <React.Fragment>
        <footer className="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-4">
                  <div className="footer-info">
                    <img
                      // src="/static/vietnamTickets/images/logo.svg"
                      // src={getResponsiveImage(
                      //   (dataSite && dataSite.logo && dataSite.logo[0] && dataSite.logo[0].file) ||
                      //   ''
                      // )}
                      className="lazyload hidden-m-t"
                      src={`${publicRuntimeConfig.IMAGE_SERVER_NEW}${
                        publicRuntimeConfig.IMAGE_PROJECT
                      }${dataSite && dataSite.logo && dataSite.logo[0] && dataSite.logo[0].file}`}
                      width="auto"
                      height="auto"
                      alt="logo"
                    />
                    {/* <p className="footer-info-text">{dataSite.seoKeywords}</p> */}
                    <ul className="footer-info-list">
                      <li className="footer-info-item ten-cong-ty">
                        <span>{publicRuntimeConfig.CONG_TY}</span>
                      </li>
                      <li className="footer-info-item">
                        {/* <img src="/static/vietnamTickets/images/Path 3650.svg" alt="Address" className='lazyload' /> */}
                        <i className="far fa-building" />
                        <span>{publicRuntimeConfig.TRU_SO}</span>
                      </li>
                      <li className="footer-info-item">
                        <img
                          src="/static/vietnamTickets/images/Path 3650.svg"
                          alt="Address"
                          className="lazyload"
                        />
                        <span>{address}</span>
                      </li>
                      <li className="footer-info-item">
                        <img
                          src="/static/vietnamTickets/images/Path 3651 (1).svg"
                          alt="Mobile"
                          className="lazyload"
                        />
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => (window.location.href = `tel:${mobile}`)}
                        >
                          {mobile}
                        </span>
                      </li>
                      <li className="footer-info-item">
                        <img
                          src="/static/vietnamTickets/images/email (1).svg"
                          alt="Email"
                          className="lazyload"
                        />
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => (window.location.href = `mailto:${email}`)}
                        >
                          {' '}
                          {email}{' '}
                        </span>
                      </li>
                      <li className="footer-info-item">
                        <img
                          src="/static/vietnamTickets/images/mst.svg"
                          alt="mst"
                          className="lazyload"
                        />
                        <span> {publicRuntimeConfig.MST} </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="row">
                    {menuFooter && menuFooter.length > 0 && menuFooter[0] && (
                      <div className="col-lg-3" key={menuFooter[0].id}>
                        <div className="footer-wrapper-mid">
                          <div className="footer-title">{menuFooter[0].name}</div>
                          {menuFooter[0].children &&
                            menuFooter[0].children.length > 0 &&
                            menuFooter[0].children.map(item1 => (
                              <ul className="footer-wrapper-mid-list" key={item1.id}>
                                <li className="footer-wrapper-mid-item" key={item1.id}>
                                  <a href={`/${item1.urlSlugs}`} title={item1.name}>
                                    {item1.name}
                                  </a>
                                </li>
                              </ul>
                            ))}
                          {/* {dataProvinces && dataProvinces.length > 0 && (
                            <ul className="footer-wrapper-mid-list">
                              {dataProvinces.map(x => (
                                <li className="footer-wrapper-mid-item" key={x.id}>
                                  <a style={{ cursor: 'pointer' }} onClick={() => this.handleProvince(x)}>
                                    {x.provinceName}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          )} */}
                        </div>
                      </div>
                    )}
                    {menuFooter && menuFooter.length > 0 && menuFooter[1] && (
                      <div className="col-lg-3" key={menuFooter[1].id}>
                        <div className="footer-wrapper-mid">
                          <div className="footer-title">{menuFooter[1].name}</div>
                          {menuFooter[1].children &&
                            menuFooter[1].children.length > 0 &&
                            menuFooter[1].children.map(item1 => (
                              <ul className="footer-wrapper-mid-list">
                                <li className="footer-wrapper-mid-item" key={item1.id}>
                                  <a href={`/${item1.urlSlugs}`} title={item1.name}>
                                    {item1.name}
                                  </a>
                                </li>
                              </ul>
                            ))}
                        </div>
                      </div>
                    )}
                    {menuFooter && menuFooter.length > 0 && menuFooter[3] && (
                      <div className="col-lg-3" key={menuFooter[3].id}>
                        <div className="footer-wrapper-mid">
                          <div className="footer-title">{menuFooter[3].name}</div>
                          {menuFooter[3].children &&
                            menuFooter[3].children.length > 0 &&
                            menuFooter[3].children.map(item1 => (
                              <ul className="footer-wrapper-mid-list">
                                <li className="footer-wrapper-mid-item" key={item1.id}>
                                  <a href={`/${item1.urlSlugs}`} title={item1.name}>
                                    {item1.name}
                                  </a>
                                </li>
                              </ul>
                            ))}
                          <div className="footer-pay hidden-pc">
                            <a href="http://online.gov.vn/Home/WebDetails/85071" target="_blank">
                              <img
                                alt=""
                                title=""
                                src="/static/vietnamTickets/images/logoSaleNoti.png"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    )}

                    {menuFooter && menuFooter.length > 0 && menuFooter[2] && (
                      <div className="col-lg-3 hidden-m-t">
                        <div className="footer-wrapper-mid">
                          <div className="footer-title">{menuFooter[2].name}</div>
                          {menuFooter[2].children &&
                            menuFooter[2].children.length > 0 &&
                            menuFooter[2].children.map(item1 => (
                              <div className="footer-wrapper-mid-para">
                                <div className="footer-wrapper-mid-para-text">{item1.name}</div>
                                <div className="footer-pay">
                                  <img
                                    src="/static/vietnamTickets/images/Group 10492.png"
                                    alt="footer pay"
                                    className="lazyload"
                                    width="250"
                                  />
                                </div>
                                <div className="footer-pay">
                                  <a
                                    href="http://online.gov.vn/Home/WebDetails/85071"
                                    target="_blank"
                                  >
                                    <img
                                      alt=""
                                      title=""
                                      src="/static/vietnamTickets/images/logoSaleNoti.png"
                                    />
                                  </a>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom hidden-m-t">
            <div className="container">
              <div className="wrapper-footer-bottom">
                <span>Copyright © 2020 mybooking.com.vn. All Rights Reserved.</span>
                {/* <span>{dataSite.seoDescriptions}</span> */}
              </div>
            </div>
          </div>
          <div className="pagiTuyenBay-top hidden-pc" />
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
